import { render, staticRenderFns } from "./zzsq.vue?vue&type=template&id=a332cbc0&scoped=true&"
import script from "./zzsq.vue?vue&type=script&lang=js&"
export * from "./zzsq.vue?vue&type=script&lang=js&"
import style0 from "./zzsq.vue?vue&type=style&index=0&id=a332cbc0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a332cbc0",
  null
  
)

export default component.exports