<template>
  <div class="msdk clearfix" id="msyc" v-lazy-background="msdkBg">
    <div class="title">
      <h3 class="titleTxt">名师荟萃</h3>
    </div>

    <div class="teacher">
      <div class="warp">
        <div class="scroll-container" ref="container">
          <ul
            class="ul-item"
            :class="{ transition: isTransition }"
            :style="{ transform: 'translateX(' + scrollLeft + 'px)' }"
          >
            <li
              v-for="(item, index) in carouselItems"
              :key="index"
              class="li-item"
            >
              <div class="image-wrapper">
                <img
                  :src="item.img"
                 
                  :style="{ width: itemWidth + 'px' }"
                  class="image-item"
                  @mouseenter="handleMouseEnter(item)"
                  @mouseleave="handleMouseLeave(item)"
                 
                />
                <div class="overlay" v-show="!item.isTrue">
                  <div class="title">
                    <h3>{{ item.info }}</h3>
                  </div>
                  <div class="infos">
                    <p>{{ item.PersonalInformation }}</p>
                  </div>
                </div>
                <div class="info-wrapper" v-if="item.isTrue">
                  <div class="info-text">{{ item.info }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="btns">
        <div class="left-arrow" @click="handleLeftArrowClick">
          <img src="../../../assets/img/arrowhead.png" />
        </div>
        <div class="right-arrow" @click="handleRightArrowClick">
          <img src="../../../assets/img/arrowhead.png" />
        </div>
      </div>
    </div>

    <div class="more" v-show="false">查看更多</div>
  </div>
</template>
    
<script>
export default {
  name: "Example03Basic",
  data() {
    return {
      itemWidth: 301,
      isPaused: false,
      msdkBg:require("../../../assets/img/msdk.png"),
      runBo: [
        // {
        //   img: require("../../../assets/img/Teacher1.png"),
        //   info: "著名歌唱家 张天甫",
        //   isTrue: true,
        //   PersonalInformation:
        //     "著名男⾼音歌唱家，中国音乐家协会会员，入选新中国70周年百名湖湘人物，国际声乐比赛评委，国家艺术基评委，美国加利福尼亚州歌剧院艺术指导。多次参加国家级大型文艺汇演，曾在多家音乐厅等地举办13场个人独唱音乐会、组织策划20多场公益音乐会。近年来演唱了50多首原创歌曲，代表作有：《湘江北去》《爱在中国》《丝路金桥》。出访十几个国家和地区，被业界誉为“硬核男高音”、“亚洲最美男高音”，获“美国国会终身艺术家奖”。",
        // },
        {
          img: require("../../../assets/img/Teacher2.png"),
          info: "著名钢琴家  李涛",
          isTrue: true,
          PersonalInformation:
            "上海音乐学院博士，旅美著名钢琴家、毕业于上海音乐学院和茱莉亚音乐学院；美国国际艺术联合会音乐家委员会主席、纽约侨联艺术总监，中国国际钢琴教育协会常务理事，美国中文电视钢琴评委，美国斯坦威合作教育家，国际女音乐家协会、英国皇家音乐协会、中国音乐家协会会员。多次应邀在联合国、卡内基音乐厅、斯坦威音乐厅、美国中文电视，及国内外一流音乐学府，举办钢琴音乐会、大师班讲座、学术研讨会和各类专业赛事评委。",
        },
        {
          img: require("../../../assets/img/Teacher3.png"),
          info: "室内乐钢琴家 张佳林",
          isTrue: true,
          PersonalInformation:
            "中央音乐学院教授，硕士研究生导师。出生于音乐世家，自幼随李斐岚老师学习钢琴。先后师从朱虹、应诗真、李民、周广仁、陈比纲、胡适熙等教授。 张佳林曾与二十余位国内外歌唱家合作录制CD、DVD及电视专题节目。2014年与张立萍合作，录制舒伯特艺术歌曲专辑《夜与梦》和中国艺术歌曲专辑《思乡》，由环球（UMG）唱片公司以Decca厂标发行。他曾在中央音乐学院音乐节上演的《弄臣》、《茶花女》、《塞魅丽》等多部歌剧中担任音乐指导。",
        },
        {
          img: require("../../../assets/img/Teacher4.png"),
          info: "著名高音歌唱家 宋丽华",
          isTrue: true,
          PersonalInformation:
            "著名高音歌唱家，国家一级演员、中国音乐家协会会员，曾五次连任西城区政协委员。原全国总工会文工团艺术骨干，主要独唱演员。其演唱音域宽厚、音色柔美、吐字清晰、声音的穿透力强。这一切有赖于其良好的声音条件，扎实的艺术功力，对声乐艺术美的深刻领悟能力。她经常出现在首都举办的大型晚会上，她的演唱不但受到观众的热烈欢迎，而且被同行们称之为实力派歌手。代表作《萨马兰奇北京欢迎您》、《胜利属于英雄的中国人民》、《叫一声妈妈》。",
        },
        {
          img: require("../../../assets/img/Teacher5.png"),
          info: "青年歌唱家  潘军",
          isTrue: true,
          PersonalInformation:
            "武警文工团青年歌唱家，国家一级演员，中国音乐家协会会员，湖南省特邀青联委员，湖南省残疾人基金会形象大使，湖南省长沙市第二福利院形象大使，曾两次荣获中央电视台全国青年歌手大奖赛专业组民族唱法优秀歌手奖，获文化部全国新人新作大赛优秀演唱奖 、多次荣获广电总局、中央人民广播电台全国听众最喜爱的歌手“十佳”歌手奖。代表作《归来兮》、《春暖花开的时候》、《绣荷包》、《中国民歌精神》等。",
        },
        {
          img: require("../../../assets/img/Teacher6.png"),
          info: "央视主持人 仲达文",
          isTrue: true,
          PersonalInformation:
            "中央电视台首届电视主持人大赛二等奖，曾主持央视《地方台三十分钟》、《每周财经资讯》、《奋斗》、《中国创业楷模》等栏目；配音解说作品在全国数百家电视台播出。播音主持艺术教授，国家级播音员主持人，曾获得北京市大学生艺术节语言类节目最佳指导教师奖。担任中国关工委《少年中国说》青少年语言艺术大赛总决赛评委，《少年中国》青少年国际文化艺术节总评委，《赢在演说》总裁班全国讲师团顾问。中华雷锋志愿者宣讲团唯一高级顾问。北京市石景山区政协常委。",
        },
        {
          img: require("../../../assets/img/Teacher7.png"),
          info: "内地男歌手 江涛",
          isTrue: true,
          PersonalInformation:
            "中国内地男歌手、演员，武警文工团独唱演员。第五届“五洲杯”全国青年歌手电视大奖赛，获得专业组通俗唱法第一名。代表作《愚公移山》《生日礼物》等，多次参加中央电视台春节联欢晚会，演唱歌曲《常回家看看》，2013年11月29日，录制深圳卫视《年代秀》节目。2015年，担任CCTV-3综艺栏目《越战越勇》第二季评委。江涛论外形不输很多新人歌手，真是名符其实的歌坛“常青树”，而其歌坛中少有的硬汉气质和霸气的演唱风格更是独树一帜。在歌坛打拼多年，江涛积累了很高的知名度，也有不少的传唱好歌。",
        },
        {
          img: require("../../../assets/img/Teacher8.png"),
          info: "青年钢琴家 廖先冀",
          isTrue: true,
          PersonalInformation:
            "亚洲最优秀的青年钢琴家，首都师范大学音乐学院教授。荣获意大利伊莫拉钢琴大师学院每个钢琴人都梦寐以求的最高文凭“艺术家文凭”。获意大利卡萨玛西玛国际钢琴比赛第一名、意大利阿玛德乌斯国际钢琴比赛第一名等十八项国际权威钢琴比赛第一名。由于他极富感染力的演奏，先后应邀在乌克兰、意大利、西班牙、德国、奥地利等国演出。世界最著名音乐名校之一意大利伊莫拉钢琴大师学院院长Franco Scala说：“廖先冀是建校以来招收的最优秀的亚洲钢琴家，他是近年来李斯特乐曲的最优秀演奏者以及诠释者。",
        },
        {
          img: require("../../../assets/img/Teacher9.png"),
          info: "书法文化讲师 胡献军",
          isTrue: true,
          PersonalInformation:
            "字章甫，号西山居士、宗军、岑夫，首都师范大学中国书法文化研究所硕士，北方工业大学讲师。中国楹联学会理事、书艺委副秘书长，中华诗词学会会员，野草诗社会员、野草书画院副秘书长，中国艺术摄影协会会员，北京周口店国际艺术区入驻职业书家。有书法集《北京奥运冠军颂》（人民美术出版社）、旧体诗词合集《守望集》（中国楹联出版社）等出版。其书各体兼攻，小大皆能，齐头并进，面貌常新；时人评其宗法传统，出入古今，风格蕴藉秀雅，体态端妍，不乏才情，颇得欧阳中石、沈鹏等大家褒许。",
        },
        {
          img: require("../../../assets/img/Teacher10.png"),
          info: "著名男高音歌唱家 康曦",
          isTrue: true,
          PersonalInformation:
            "著名男高音歌唱家，国家一级演员，从艺三十年，同时一直从事声乐教学工作，到全国各高等艺术院校授课、讲座，并受聘于多所大学。多个艺术院校的声乐外聘教授、特聘教授、客座教授、北京延安儿女联谊会形象大使、红色记忆文化基金会形象大使。典型作品有《大江南》、《洞庭鱼米香》、《小山村.大乾坤》、《忆延安》、《喊一声红嫂泪花流》、《红柳滩》、《幸福花开亲人来》、《新兵连的班长》、《好男要当兵》、《麦熟一晌》等经典曲目。",
        },
        {
          img: require("../../../assets/img/Teacher11.png"),
          info: "原空政文工团舞蹈编导 于春仪",
          isTrue: true,
          PersonalInformation:
            "原空政文工团舞蹈编导，蓝天幼儿艺术团指导，总导演，中国视协少专委副会长，中国民族民间舞高级考官。曾任空军政治部文工团创作室创作员、空军蓝天幼儿艺术团艺术指导，在大型幼儿歌舞剧《哪咤闹海》中任总导演。多次在全国儿童舞蹈培训班任教师。多次为中央电视台、北京电视台编排节目。主要作品：《打花巴掌》、《拾土豆的小妞》、《雨姑娘》、《皎皎白玉兰》、《赶海》、《放学路上》、《伤逝》、《哪咤闹海》等。",
        },
        {
          img: require("../../../assets/img/Teacher12.png"),
          info: "著名男高音歌唱家 赵登峰",
          isTrue: true,
          PersonalInformation:
            "旅德著名男高音歌唱家，国家一级演员，曾多次参加国内国际声乐比赛。1994年赵登峰在第六届“通业杯”全国青年歌手电视大赛中获得美声唱法专业组第一名。1993年赵登峰在维也纳“Bevedere”国际声乐比赛中一举获得最佳男高音奖。引起了欧洲歌剧界的瞩目。1995年，他又在挪威“Konigosonja”国际声乐比赛中获得第二名，之后他又在德国“L—CESTELLI”国际声乐比赛中拿下了第一名的桂冠。作为在世界歌剧界具有高度知名度的中国歌唱家之一，曾经在十多部歌剧中担任男主角。",
        },
        {
          img: require("../../../assets/img/Teacher13.png"),
          info: "旅美男中音歌唱家 吴柏成",
          isTrue: true,
          PersonalInformation:
            "旅美男中音歌唱家，美国中西部大学国际艺术与音乐学院院长、教授、博士生导师；重庆师范大学涉外音乐舞蹈学院歌剧中心主任、特聘教授。曾获美国国会“音乐艺术终身成就奖”；美国亚凯迪亚市政府市长颁发“全球杰出音乐家奖”；美国安娜海姆市政府市长授予“国际歌剧演唱家”称号；美国奥斯汀市“荣誉市民”。美国加州亚凯迪亚市市长宣布2018年9月18日为该市的“吴柏成日”并授予他“荣誉市长”称号。代表作品有《图兰朵》。",
        },
        {
          img: require("../../../assets/img/Teacher14.png"),
          info: "著名民族民间舞教授 刘翠玉",
          isTrue: true,
          PersonalInformation:
            "舞蹈老前辈，原解放军艺术学院民间舞创始人兼教研组组长，著名民族民间舞教授。",
        },
        {
          img: require("../../../assets/img/Teacher15.png"),
          info: "皇后画院院长教授 刘比华",
          isTrue: true,
          PersonalInformation:
            "毕业于广州美术学院，1984年到美国留学，1998年在美国纽约创办皇后画院，现任皇后画院院长教授。父亲为书法家、篆刻家刘寿强，在美国经过20多年的刻苦努力，独创了“流彩油画技法和流彩油画颜料”，成为美国流彩油画创始人，2005年6月他获得美国专利局批发“全新绘画方法---流彩油画”专利。刘比华先生捐赠的一幅流彩油画《琉璃花瓶》，已于2010年12月15日被广东华侨博物馆收藏。",
        },
        {
          img: require("../../../assets/img/Teacher16.png"),
          info: "原央视少儿频道制片主任 张元鹏",
          isTrue: true,
          PersonalInformation:
            "原中央电视台少儿频道制片主任、少儿频道高级制片经济师、中国电视制片委员会理事，杰出影视剧演员。曾出演电視剧《三国演义》饰庞德，电視剧《毛泽东在西柏坡》饰任弼时，参加多部影视剧配音，制作，导演多部儿童舞台剧等。",
        },
        {
          img: require("../../../assets/img/Teacher17.png"),
          info: "原中央芭蕾舞团一级演员 宋琛",
          isTrue: true,
          PersonalInformation:
            "原中央芭蕾舞团国家一级演员，舞剧（红色娘子军）连长，舞蹈（草原儿女）妈妈的扮演者。",
        },
        {
          img: require("../../../assets/img/Teacher18.png"),
          info: "原总政文工团领舞 庄西安",
          isTrue: true,
          PersonalInformation:
            "原总政文工团领舞，学员队政委，原文化部下属博爱国际孤儿艺术学校，校长。",
        },
        {
          img: require("../../../assets/img/Teacher19.png"),
          info: "国家一级舞蹈演员 孙鹏",
          isTrue: true,
          PersonalInformation:
            "青年舞蹈家，中国东方演艺集团，国家一级舞蹈演员。",
        },
        {
          img: require("../../../assets/img/Teacher20.png"),
          info: "中央民歌舞团舞蹈演员 李蓝紫",
          isTrue: true,
          PersonalInformation:
            "中央民族歌舞团舞蹈演员，著名舞蹈家陈爱莲老师的爱徒，爱莲版舞剧（红楼梦）王熙凤的扮演者。",
        },
        {
          img: require("../../../assets/img/Teacher21.png"),
          info: "中央民族歌舞团摄影师 翟红屹",
          isTrue: true,
          PersonalInformation:
            "中央民族歌舞团摄影师，中国舞蹈家协会舞蹈影像培训班成员，北京市摄影家协会会员，独立影像制片人，影片《朱美静》获得重庆电影节最佳短片奖，担任总制片，参加第六届少数民族文艺会演，担任剧照摄影师。",
        },
      ],
      visibleItems: [],
      currentIndex: 0,
      scrollInterval: null,
      scrollLeft: 0,
      isTransition: false,
      isVisible: false,
    };
  },
  mounted() {
    if (window.location.hash === "#msyc") {
      const msyc = document.getElementById("msyc");
      msyc.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // 可见阈值
    };

    const observer = new IntersectionObserver(this.handleIntersection, options);
    observer.observe(this.$el);
    this.setupScroll();
  },
  beforeDestroy() {
    this.destroyScroll();
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 组件进入可见区域
          this.isVisible = true;
          this.setupScroll();
        } else {
          // 组件离开可见区域
          this.isVisible = false;
          this.destroyScroll();
        }
      });
    },
    handleMouseEnter(item) {
      clearInterval(this.scrollInterval);
      item.isTrue = false;
    },

    handleMouseLeave(item) {
      this.setupScroll();
      item.isTrue = true;
    },
    handleLeftArrowClick() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      }
      this.updateVisibleItems();
    },

    handleRightArrowClick() {
      this.currentIndex++;
      if (this.currentIndex >= this.runBo.length) {
        this.currentIndex = this.runBo.length - 1;
      }
      this.updateVisibleItems();
    },
    setupScroll() {
      this.updateVisibleItems();
      this.scrollInterval = setInterval(() => {
        this.isTransition = true;
        setTimeout(() => {
          this.currentIndex++;
          if (this.currentIndex >= this.runBo.length) {
            this.currentIndex = 0;
            this.isTransition = false;
            this.$nextTick(() => {
              this.scrollLeft = 0;
              this.resetItems();
            });
          } else {
            this.updateVisibleItems();
          }
        }, 500);
      }, 3000);
    },
    destroyScroll() {
      clearInterval(this.scrollInterval);
    },
    updateVisibleItems() {
      const numVisibleItems = 3;
      const start = this.currentIndex;
      const end = start + numVisibleItems;
      const carouselLength = this.runBo.length;

      if (end <= carouselLength) {
        this.visibleItems = this.runBo.slice(start, end);
      } else {
        this.visibleItems = [
          ...this.runBo.slice(start),
          ...this.runBo.slice(0, end - carouselLength),
        ];
      }

      this.scrollTo(this.currentIndex);
    },

    scrollTo(index) {
      this.$nextTick(() => {
        const containerWidth = this.$refs.container.clientWidth;
        const targetScrollLeft = -index * (containerWidth / 3);
        this.scrollLeft = targetScrollLeft;
      });
    },

    resetItems() {
      this.runBo.forEach((item) => {
        item.isTrue = true;
      });
    },
  },
  computed: {
    carouselItems() {
      return this.runBo.concat(this.runBo);
    },
  },
};
</script>
  <style lang="less" scoped>
.msdk {
  width: 100%;
  height: 700px;
 
  position: relative;

  .title {
    margin-top: 60px;
  }

  .small {
    text-align: center;

    p {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .more {
    width: 179px;
    height: 41px;
    background: #e20305;
    border-radius: 21px;
    text-align: center;
    color: white;
    line-height: 41px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    bottom: 150px;
  }
  .teacher {
    .warp {
      width: 1000px;
      height: 481px;
      margin: 40px auto;
      overflow: hidden;
      position: relative;
      .scroll-container {
        width: 100%;
        overflow: hidden;
        scroll-behavior: smooth;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          position: relative;
          left: 0;
          width: max-content;
          justify-content: space-evenly;

          &.ul-item {
            .li-item {
              height: 481px;
              margin-right: 33px;
              position: relative;

              .image-wrapper {
                position: relative;
              }

              img.image-item {
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }

      .transition {
        transition: transform 0.5s;
      }
      .info-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: #3a353594;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .info-text {
        color: white;
      }
      .image-wrapper {
        position: relative;
      }

      .image-wrapper .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 1;
        transition: opacity 0.3s ease;
        pointer-events: none;
        .title {
          margin: 30px auto;
          text-align: center;
          font-size: 20px;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
        }
        .infos {
          margin: 30px auto;
          width: 277px;
          height: 322px;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          line-height: 25px;
        }
      }

      .image-wrapper .overlay {
        opacity: 0;
      }

      .image-wrapper:hover .overlay {
        opacity: 1;
      }
    }
    .btns {
      width: 1150px;
      height: 60px;
      display: flex;
      margin: 0 auto;
      margin-top: -300px;
      justify-content: space-between;
      cursor: pointer;
      img {
        width: 59px;
      }
      .right-arrow {
        transform: rotate(180deg);
        margin-right: 10px;
      }
    }
  }
}
</style>