<template>
    <div class="index">
       <HeadTop/>
    </div>
</template>

<script>
import HeadTop from '../../pubilic/HeadTop.vue';


export default {
  components: { HeadTop, },
    
}
</script>

