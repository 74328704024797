<template>
  <div class="mxkc clearfix" ref="mxkc" id="mxkc" v-lazy-background="mxkcBg">
    <div class="title">
      <h3 class="titleTxt">明星课程</h3>
    </div>
    <div class="test" v-lazy-background="testBg">
      <div class="text-info w">
        <div
          v-for="(item, index) in items"
          :key="index"
          :ref="`element-${item.id}`"
          :id="item.id"
          :class="[
            item.className,
            'animate__animated',
            getAnimationClass(item.id),
            { 'animate__delay-1s': !item.isVisible },
          ]"
        >
          <ul>
            <li
              v-for="(text, liIndex) in item.text"
              :key="liIndex"
              @click="toPiano(text)"
            >
              {{ text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mxkcBg:require("../../../assets/img/mxkcBg.png"),
      testBg:require("../../../assets/img/test.png"),
      items: [
        {
          id: "element1",
          text: ["表演基础", "影视化妆", "后期制作", "编辑", "摄影"],
          className: "show-chiliden",
          isVisible: false,
        },
        {
          id: "element2",
          text: ["口才与演讲", "少儿朗读", "科学发声", "主持艺术"],
          className: "show-chiliden2",
          isVisible: false,
        },
        {
          id: "element3",
          text: [
            "西洋乐器",
            "民族乐器",
            "电声乐器",
            "声乐合唱",
            "钢琴\\电子琴",
          ],
          className: "show-chiliden3",
          isVisible: false, // Add isVisible property
        },
        {
          id: "element4",
          text: ["成人肚皮舞", "少儿拉丁舞", "中国舞", "爵士舞", "拉丁舞"],
          className: "show-chiliden4",
          isVisible: false,
        },
        {
          id: "element5",
          text: ["创意绘画", "动漫插画", "素描", "国画", "油画", "版画"],
          className: "show-chiliden5",
          isVisible: false,
        },
        {
          id: "element6",
          text: ["硬笔书法", "软笔书法", "中高考书法"],
          className: "show-chiliden6",
          isVisible: false,
        },
      ],
      observer: null,
    };
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        const elementId = entry.target.id;
        const item = this.items.find((item) => item.id === elementId);
        if (entry.isIntersecting) {
          // 元素进入可视区域
          item.isVisible = true;
          entry.target.classList.remove(this.getAnimationClass(item.id)); // 移除动画类
          void entry.target.offsetWidth; // 强制重绘，以便重新播放动画
          entry.target.classList.add(
            "animate__animated",
            this.getAnimationClass(item.id)
          );
        } else {
          // 元素离开可视区域
          item.isVisible = false;
          entry.target.classList.remove(
            "animate__animated",
            this.getAnimationClass(item.id)
          );
        }
      });
    },
    toPiano(Txt) {
      if (Txt === "钢琴\\电子琴") {
        //this.$router.push("/piano");
        return
      }
      if(Txt === "成人肚皮舞" || Txt === "少儿拉丁舞" || Txt === "中国舞" || Txt === "爵士舞" ||  Txt === "拉丁舞"){
        this.$router.push("/Dance");
      }
      if(Txt === "创意绘画" || Txt === "动漫插画" || Txt === "素描" || Txt === "国画" || Txt === "油画" || Txt === "版画"){
        this.$router.push("/Painting");
      }
    },
    getAnimationClass(elementId) {
      const index = this.items.findIndex((item) => item.id === elementId);
      if (index % 2 === 0) {
        return "animate__fadeInDown";
      } else {
        return "animate__fadeInUp";
      }
    },
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.items.forEach((item) => {
      const element = document.getElementById(item.id);
      if (element) {
        this.observer.observe(element);
      }
    });
  },

  beforeDestroy() {
    this.observer.disconnect();
  },
};
</script>

<style lang="less" scoped>
.mxkc {
  width: 100%;
  height: 900px;

  .title {
    margin-top: 70px;
  }

  .test {
    width: 1000px;
    margin: 40px auto;
    height: 654px;

    .text-info {
      position: absolute;
      z-index: 3;
      height: 659px;
      width: 1000px;
      left: 50%;
      transform: translate(-50%);
      display: flex;

      .show-chiliden,
      .show-chiliden2,
      .show-chiliden3,
      .show-chiliden4,
      .show-chiliden5,
      .show-chiliden6 {
        width: 25%;
        height: auto;

        position: absolute;
        z-index: 10;

        ul {
          width: 100%;
          display: flex;

          li {
            writing-mode: tb-rl;
            color: white;
            padding: 22px 3px;
            cursor: pointer;
            transition: all 0.3s;
          }
        }

        ul > li:hover {
          color: #2079ff;
        }
      }

      .show-chiliden {
        left: 4%;
      }

      .show-chiliden2 {
        left: 17%;
        bottom: 2%;
      }

      .show-chiliden3 {
        left: 32%;
      }

      .show-chiliden4 {
        left: 48%;
        bottom: 2%;
      }

      .show-chiliden5 {
        left: 66%;
      }

      .show-chiliden6 {
        left: 84%;
        bottom: 2%;
      }
    }
  }

  .item {
    width: 748px;
    height: 659px;
    background: url(../../../assets/img/yellow.png) no-repeat center;
    background-size: cover;
    margin: 90px auto;

    position: relative !important;
    z-index: 9;

    .imgBox {
      display: flex;
      height: auto;
      justify-content: center;
      position: relative;

      .img1,
      .img2,
      .img3,
      .img4,
      .img5,
      .img6 {
        position: absolute;
      }

      .img1 {
        left: -130px;
      }

      .img2 {
        left: 0px;
        z-index: 1;
      }

      .img3 {
        left: 162px;
        z-index: 0;
      }

      .img4 {
        left: 309px;
      }

      .img5 {
        left: 486px;
        z-index: 1;
      }

      .img6 {
        left: 643px;
        z-index: 0;
      }
    }

    .text-info {
      position: absolute;
      z-index: 3;
      height: 659px;
      width: 1000px;
      left: 50%;
      transform: translate(-50%);
      display: flex;

      .show-chiliden,
      .show-chiliden2,
      .show-chiliden3,
      .show-chiliden4,
      .show-chiliden5,
      .show-chiliden6 {
        width: 25%;
        height: auto;

        position: absolute;
        z-index: 10;

        ul {
          width: 100%;
          display: flex;

          li {
            writing-mode: tb-rl;
            color: white;
            padding: 22px 6px;
            cursor: pointer;
            transition: all 0.3s;
          }
        }

        ul > li:hover {
          color: #2079ff;
        }
      }

      .show-chiliden {
        left: 15px;
      }

      .show-chiliden2 {
        left: 15%;
        bottom: 2%;
      }

      .show-chiliden3 {
        left: 31%;
      }

      .show-chiliden4 {
        left: 47%;
        bottom: 2%;
      }

      .show-chiliden5 {
        left: 64%;
      }

      .show-chiliden6 {
        left: 85%;
        bottom: 2%;
      }
    }
  }
}
</style>