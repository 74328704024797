<template>
  <div class="hzhb clearfix" id="hzhb" v-lazy-background="hzhbBg">
    <div class="title">
      <h3 class="titleTxt">合作伙伴</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hzhbBg:require("../../../assets/img/hzhb.png"),
    }
  }
}
</script>

<style lang="less" scoped>
.hzhb {
  width: 100%;
  height: 900px;


  .title {
    margin-top: 80px;
  }
}
</style>