<template>
  <div class="qualifications clearfix" v-lazy-background="qualificationsBg">
    <div class="title" id="zzsq">
      <h3 class="titleTxt">资质授权</h3>
    </div>
    <div class="box">
      <img
        v-for="(image, index) in images"
        :key="index"
        :src="image.src"
        v-lazy-img="image.src"
        :alt="image.alt"
        :class="[
          'animate__animated',
          {
            animate__fadeInLeft: index % 2 === 0 && image.isVisible,
            animate__fadeInRight: index % 2 !== 0 && image.isVisible,
          },
          { 'animate__delay-1s': !image.isVisible },
        ]"
      />
    </div>
  </div>
</template>
    
  <script>
export default {
  data() {
    return {
      images: [
        {
          src: require("../../../assets/img/qualifications4.png"),
          alt: "",
          isVisible: false,
        },
        {
          src: require("../../../assets/img/qualifications2.png"),
          alt: "",
          isVisible: false,
        },
        {
          src: require("../../../assets/img/qualifications3.png"),
          alt: "",
          isVisible: false,
        },
        {
          src: require("../../../assets/img/qualifications1.png"),
          alt: "",
          isVisible: false,
        },
      ],
      qualificationsBg: require("../../../assets/img/xingkongBg.jpg"),
      observer: null,
    };
  },

  mounted() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
    if (window.location.hash === "#jxsk") {
      const jxsk = document.getElementById("jxsk");
      jxsk.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.$nextTick(() => {
      const images = this.$el.querySelectorAll(".box img");
      images.forEach((image, index) => {
        image.dataset.index = index;
        this.observer.observe(image);
      });
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        const index = entry.target.dataset.index;
        if (entry.isIntersecting) {
          this.images[index].isVisible = true;
        } else {
          this.images[index].isVisible = false;
        }
      });
    },
  },
};
</script>
  
  

<style lang="less" scoped>
.qualifications {
  width: 100%;
  height: 900px;

  .title {
    margin-top: 90px;
    position: relative;
    top: -50px;
  }
  .box {
    width: 1000px;
    height: auto;
    margin: 0 auto;
    left: 50%;
    margin-top: -20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  img {
    width: 500px;
    object-fit: cover;
  }
}
</style>