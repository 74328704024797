var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kctxBg clearfix",style:({ backgroundImage: `url(${_vm.backgroundImage})` }),attrs:{"id":"kctx"}},[_vm._m(0),_c('div',{staticClass:"box"},[_c('div',{ref:"ytjyLeft",staticClass:"ytjy-left",class:{
        'animate__animated animate__lightSpeedInLeft': _vm.isVisible.ytjyLeft,
      },attrs:{"data-key":"ytjyLeft"}},[_c('p',[_vm._v("影视表演")]),_c('p',{staticStyle:{"position":"relative","top":"1.5%"}},[_vm._v("音乐")]),_c('p',{staticStyle:{"position":"relative","top":"6%"}},[_vm._v("美术")])]),_c('div',{ref:"ytjyRight",staticClass:"ytjy-right",class:{
        'animate__animated animate__slideInRight': _vm.isVisible.ytjyRight,
      },attrs:{"data-key":"ytjyRight"}},[_c('p',[_vm._v("播音主持")]),_c('p',{staticStyle:{"position":"relative","top":"1.5%"}},[_vm._v("舞蹈")]),_c('p',{staticStyle:{"position":"relative","top":"6%"}},[_vm._v("书法")])]),_c('div',{staticClass:"four-txt"},[_c('p',{ref:"fourTxt1",class:{
          'animate__animated animate__lightSpeedInLeft': _vm.isVisible.fourTxt1,
        },staticStyle:{"position":"relative","left":"-3%"},attrs:{"data-key":"fourTxt1"}},[_vm._v(" 教学方式 ")]),_c('p',{ref:"fourTxt2",class:{
          'animate__animated animate__lightSpeedInLeft': _vm.isVisible.fourTxt2,
        },staticStyle:{"position":"relative","left":"-0.5%"},attrs:{"data-key":"fourTxt2"}},[_vm._v(" 课程体系 ")]),_c('p',{ref:"fourTxt3",class:{
          'animate__animated animate__lightSpeedInRight': _vm.isVisible.fourTxt3,
        },staticStyle:{"position":"relative","left":"2.1%"},attrs:{"data-key":"fourTxt3"}},[_vm._v(" 教学理念 ")]),_c('p',{ref:"fourTxt4",class:{
          'animate__animated animate__lightSpeedInRight': _vm.isVisible.fourTxt4,
        },staticStyle:{"position":"relative","left":"4.7%"},attrs:{"data-key":"fourTxt4"}},[_vm._v(" 课程疏导 ")])]),_c('div',{staticClass:"footer-txt"},[_c('div',{ref:"footerTxt1",staticClass:"content",class:{
          'animate__animated animate__lightSpeedInLeft': _vm.isVisible.footerTxt1,
        },staticStyle:{"position":"relative","left":"-1%"},attrs:{"data-key":"footerTxt1"}},[_c('p',[_vm._v("线上+线下")]),_c('p',[_vm._v("直播+录播")])]),_c('div',{ref:"footerTxt2",staticClass:"content",class:{
          'animate__animated animate__lightSpeedInLeft': _vm.isVisible.footerTxt2,
        },staticStyle:{"position":"relative","left":"0.8%"},attrs:{"data-key":"footerTxt2"}},[_c('p',[_vm._v("6大专业课程")]),_c('p',[_vm._v("满足艺术所需")])]),_c('div',{ref:"footerTxt3",staticClass:"content",class:{
          'animate__animated animate__lightSpeedInRight':
            _vm.isVisible.footerTxt3,
        },staticStyle:{"position":"relative","left":"1%"},attrs:{"data-key":"footerTxt3"}},[_c('p',[_vm._v("激发艺术潜能")]),_c('p',[_vm._v("成就艺术梦想")])]),_c('div',{ref:"footerTxt4",staticClass:"content",class:{
          'animate__animated animate__lightSpeedInRight':
            _vm.isVisible.footerTxt4,
        },staticStyle:{"position":"relative","left":"2.2%"},attrs:{"data-key":"footerTxt4"}},[_c('p',[_vm._v("量身定制教学计划")]),_c('p',[_vm._v("专业导师搭建演出平台")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"bottom-btn"},[_vm._v("点击咨询")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',{staticClass:"titleTxt"},[_vm._v("课程体系")])])
}]

export { render, staticRenderFns }