<template>
  <div class="banner">
    <el-carousel
      :interval="3000"
      arrow="never"
      height="1077px"
      @change="handleChange"
    >
      <el-carousel-item v-for="(item, index) in banner" :key="index">
        <div class="txt">
          <h3>{{ currentBannerTxt.title }}</h3>
          <p>{{ currentBannerTxt.info }}</p>
        </div>
        <img :src="item" @click="toIndex(index)" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      banner: [
        require("../../../assets/img/banner1.png"),
        // require("../../../assets/img/banner2.png"),
      ],
      bannerTxt: [
        {
          title: `北京艺途国际艺术培训有限公司是北京文化（A股上市公司，股票代码000802）旗下的子公司，主要从事艺术教育培训业务。`,
          info: `北京文化全称北京京西文化旅游股份有限公司，是一家全产业链影视娱乐传媒集团，公司于1998年在深交所上市，背靠优质旅游文化资源。
                   北京文化通过整合娱乐产业上下游资源，搭建多位一体的泛娱乐产业链，致力于成为泛娱乐产业的领导者。
                    近几年北京文化主控出品多部具有影响力的电影，包括《战狼二》、《我不是药神》、《无名之辈》、《芳华》、《我不是潘金莲》、《流浪地球》、《流浪地球2》等影视剧作，
                    另外还有多部电视剧和综艺节目，已经成为具有影响力的行业领先公司。`,
        },
        {
          title: `北京艺途国际艺术培训有限公司是北京文化（A股上市公司，股票代码000802）旗下的子公司，主要从事艺术教育培训业务。`,
          info: `北京文化全称北京京西文化旅游股份有限公司，是一家全产业链影视娱乐传媒集团，公司于1998年在深交所上市，背靠优质旅游文化资源。
                   北京文化通过整合娱乐产业上下游资源，搭建多位一体的泛娱乐产业链，致力于成为泛娱乐产业的领导者。
                    近几年北京文化主控出品多部具有影响力的电影，包括《战狼二》、《我不是药神》、《无名之辈》、《芳华》、《我不是潘金莲》、《流浪地球》、《流浪地球2》等影视剧作，
                    另外还有多部电视剧和综艺节目，已经成为具有影响力的行业领先公司。`,
        },
      ],
    };
  },
  methods: {
    toIndex(flag) {},
    handleChange(index) {
      this.currentIndex = index;
    },
  },
  computed: {
    currentBannerTxt() {
      return this.bannerTxt[this.currentIndex];
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 1077px;
  margin-top: 65px;

  .txt {
    width: 40%;
    height: 300px;
    position: absolute;
    bottom: 2%;
    left: 60%;
    transform: translate(-60%);
    color: #ffffff;

    h3 {
      margin-top: 30px;
      font-size: 18px;
      font-weight: bold;
      text-indent: 2rem;
    }

    p {
      text-indent: 2rem;
      line-height: 27px;
    }
  }

  img {
    width: 100%;
    height: 1077px;
    object-fit: cover;
  }
}
</style>