<template>
  <div class="SideRight" ref="sideRight">
    <!-- <div class="box">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-zixun"></use>
        </svg>
        <a
          href="javascript:void(0)"
          onclick="openJesongChatByGroup(34844,53391);return false;"
          ><p>咨询</p></a
        >
      </div> -->
    <div class="box" @mousemove="move" @mouseleave="moleave">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-gongzhonghao"></use>
      </svg>
      <p>公众号</p>
      <img src="../../assets/img/wechat.png" alt="" v-show="isShow" />
    </div>
    <div class="box" @mousemove="moveTel" @mouseleave="moleaveTel">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-24gl-phone24h"></use>
      </svg>
      <p>电话</p>
      <div class="phone" v-show="isTel">400-801-8866</div>
    </div>
    <div class="box">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-fanhui3"></use>
      </svg>
      <a href="#">
        <p class="last">
          返回

          <br />顶部
        </p>
      </a>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "SideRight",
  data() {
    return {
      isShow: false,
      isTel: false,
    };
  },
  methods: {
    move() {
      this.isShow = true;
    },
    moleave() {
      this.isShow = false;
    },
    moveTel() {
      this.isTel = true;
    },
    moleaveTel() {
      this.isTel = false;
    },
    adjustPosition() {
      const sideRight = this.$refs.sideRight;
      const screenHeight = window.innerHeight;
      const ratio = 0.5; // 调整位置的比例，根据需要进行调整

      const topPosition = screenHeight * ratio + "px";
      sideRight.style.top = topPosition;
    },
  },
  mounted() {
    this.adjustPosition();
    window.addEventListener("resize", this.adjustPosition);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustPosition);
  },
  
};
</script>
  
  <style lang="less" scoped>
.SideRight {
  width: 80px;
  height: 280px;
  position: fixed;
  right: 0;
  background: #ffffff;
  z-index: 99999;
  // top: 360px;
  text-align: center;
  border-radius: 10px;
  border-left: 1px rgb(194, 194, 194) solid;
  box-shadow: 0 16rpx 16rpx rgba(10, 16, 20, 0.24),
    0 0 16rpx rgba(10, 16, 20, 0.12);
  box-sizing: content-box;
  .box {
    width: 100%;
    height: 87px;
    padding: 3px;
    cursor: pointer;
    img {
      width: 97px;
      position: absolute;
      left: -100px;
      top: 0px;
      object-fit: cover;
    }
    .phone {
      position: absolute;
      width: 160px;
      height: 70px;
      background: #3556f5;
      right: 90px;
      top: 100px;

      line-height: 70px;
      color: rgb(255, 255, 255);
      border-radius: 10px;
      z-index: 9999;
    }
    p {
      height: 32px;
      font-size: 14px;
      font-weight: 400;
      color: #7d7d7d;
      line-height: 16px;
      text-shadow: 0px 2px 4px rgba(169, 169, 176, 0.5);
      border-bottom: 1px solid rgb(208, 208, 208);
    }
    .last {
      border-bottom: 1px solid transparent !important;
      height: 20px;
    }
  }
  .conter {
    height: 87px;
    cursor: pointer;
    width: 100%;
    .phone {
      position: absolute;
      width: 100px;
      height: 30px;
      background: #3556f5;
      left: -100px;
      top: 120px;
      line-height: 30px;
      color: white;
      border-radius: 10px;
      z-index: 9999;
    }
    img {
      width: 100px;
      position: absolute;
      left: -100px;
      top: 40px;
      object-fit: cover;
    }
    p {
      border-bottom: 1px solid rgb(208, 208, 208);
    }
  }
  .last {
    border-bottom: 1px solid transparent !important;
  }
  .icon {
    width: 2em;
    height: 2em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
}
</style>