<template>
  <div class="top">
    <transition
      name="fade"
      enter-active-class="animate__animated animate__fadeInRight "
      leave-active-class="animate__animated animate__fadeOutRight"
    >
      <SideRight v-show="$store.state.sideRight" />
    </transition>
    <div class="Head">
      <TitleYt></TitleYt>
      <nav-top></nav-top>
      <banner />
      <kctx />
      <mxkc />
      <teach-list/>
      <jxsk/>

      <zzsq />
     
      <hzhb/>
      <mtxw/>
      <copyright />
    </div>
  </div>
</template>

<script>
import "../../assets/css/public.css";
import TeachList from "../page/home/TeachList.vue";
import Copyright from "./copyright.vue";
import NavTop from "./NavTop.vue";
import SideRight from "./SideRight.vue";
import TitleYt from "./TitleYt.vue";
import Banner from "../page/home/Banner.vue";
import Kctx from "../page/home/Kctx.vue";
import Mxkc from "../page/home/mxkc.vue";
import Zzsq from "../page/home/zzsq.vue";
import Jxsk from '../page/home/Jxsk.vue';
import Hzhb from '../page/home/Hzhb.vue';
import Mtxw from '../page/home/Mtxw.vue';
export default {
  components: {
    SideRight,
    TitleYt,
    NavTop,
    Copyright,
    TeachList,
    Banner,
    Kctx,
    Mxkc,
    Zzsq,
    Jxsk,
    Hzhb,
    Mtxw,
  },
  name: "HeadTop",
  data() {
    return {
    
    };
  },
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 20) {
        this.$store.commit("updateSideRight", true);
      } else if (scrollTop < 150) {
        this.$store.commit("updateSideRight", false);
      }
    },

   
  },
  mounted() {
  window.addEventListener("scroll", this.handleScroll);
  this.handleScroll();
}

};
</script>
<style lang="less" scoped>
.Head {
  width: 100%;
  height: 31px;
  border-radius: 1px;
}



</style>

