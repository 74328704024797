<template>
  <div class="jxsk clearfix" id="jxsk" v-lazy-background="jxskBg">
    <div class="title">
      <h3 class="titleTxt">教学实况</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jxskBg:require("../../../assets/img/jxskBg.png"),
    }
  },
  mounted() {
    if (window.location.hash === "#jxsk") {
      const jxsk = document.getElementById("jxsk");
      jxsk.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
};
</script>

<style lang="less" scoped>
.jxsk {
  width: 100%;
  height: 900px;
  margin-top: -2px;
  position: relative;
  .title {
    margin-top: 60px;
  }

  .small {
    text-align: center;

    p {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>