var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"lazy-background",rawName:"v-lazy-background",value:(_vm.qualificationsBg),expression:"qualificationsBg"}],staticClass:"qualifications clearfix"},[_vm._m(0),_c('div',{staticClass:"box"},_vm._l((_vm.images),function(image,index){return _c('img',{directives:[{name:"lazy-img",rawName:"v-lazy-img",value:(image.src),expression:"image.src"}],key:index,class:[
        'animate__animated',
        {
          animate__fadeInLeft: index % 2 === 0 && image.isVisible,
          animate__fadeInRight: index % 2 !== 0 && image.isVisible,
        },
        { 'animate__delay-1s': !image.isVisible },
      ],attrs:{"src":image.src,"alt":image.alt}})}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title",attrs:{"id":"zzsq"}},[_c('h3',{staticClass:"titleTxt"},[_vm._v("资质授权")])])
}]

export { render, staticRenderFns }