<template>
  <div class="mtxwBg clearfix" id="mtxw"  v-lazy-background="mtxwBg">
    <div class="title">
      <h3 class="titleTxt">媒体新闻</h3>
    </div>
    <div class="box w">
      <div class="left-info">
        <div class="bg" @click="medioToNews(media[0].link)" v-lazy-background="qwfbBg">
          <div class="bottom-txt">
            <div class="title-txt">
              <div class="top">
                <h3>{{ media[0].title }}</h3>
              </div>
              <div class="info">
                {{ media[0].info }}
              </div>
              <div class="line"></div>
              <div class="time">{{ media[0].time }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-info">
        <div
          class="Mediabox"
          v-for="(item, i) in media"
          :key="i"
          @click="medioToNews(item.link)"
        >
          <div class="content-left">
            <img :src="item.img"  v-lazy-img="item.img"/>
          </div>
          <div class="content-right">
            <div class="title-txt">{{ item.title }}</div>
            <div class="small">
              {{ item.info }}
            </div>
            <div class="line"></div>
            <div class="time">
              {{ item.time }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mtxwBg:require("../../../assets/img/MtxwBg.png"),
      qwfbBg:require("../../../assets/img/qwfbBox.png"),
      media: [
        {
          img: require("../../../assets/img/news2.png"),
          title: "艺途国际5·20“放大招”，举办专家...",
          info: "2023年5月20日,在这个特殊的日子里,艺途国际艺术培训学校客座教授受聘仪式暨专家教学研讨会在北京万寿宾馆举行...",
          link: "http://life.china.com.cn/gg/zixun/detail2_2023_05/24/3975101.html",
          time: "2023-5-24",
        },
        {
          img: require("../../../assets/img/news1.png"),
          title: "艺途国际钢琴课掀起银发一族“文化养...",
          info: "2022年发布的《“十四五”国家老龄事业发展和养老服务体系规划》中重点提到,要积极推进中老年教育培训体系,增强他们的获得..",
          link: "http://www.chinadevelopment.com.cn/zxsd/2023/0511/1838073.shtml",
          time: "2023-05-11",
        },
        {
          img: require("../../../assets/img/news3.png"),
          title: "艺途国际中老年钢琴网课 让退休生活...",
          info: "随着互联网的高速发展和手机等智能设备的广泛普及,学习“网课”早已不再是学生...",
          link: "https://m.tech.china.com/hea/article/20230510/052023_1272064.html",
          time: "2023-05-10",
        },
      ],
    };
  },
  methods: {
    medioToNews(url) {
      window.open(url);
    },
  },
  mounted() {
    if (window.location.hash === "#mtxw") {
      const mtxw = document.getElementById("mtxw");
      mtxw.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
};
</script>

<style lang="less" scoped>
.mtxwBg {
  width: 100%;
  height: 800px;
  .title {
    margin-top: 80px;
  }
  .box {
    margin-top: 40px;
    height: 580px;
    display: flex;
    .left-info {
      width: 38%;
      height: 100%;
      .bg {
        width: 346px;
        height: 580px;
        
        position: relative;
        .bottom-txt {
          width: 330px;
          height: 195px;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%);
          display: flex;
          .title-txt {
            width: 100%;
            cursor: pointer;
            .top {
              width: 86%;
              text-align: center;
              margin-top: 10px;
              margin-left: 17px;

              h3 {
                font-size: 16px;
                font-weight: bold;
                color: #333333;
              }
            }

            .info {
              width: 86%;
              margin: 10px auto;
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #666666;
            }
            .top h3,
            .info {
              transition: all 0.3s;
            }

            .top h3:hover,
            .info:hover {
              color: #2079ff;
            }

            .line {
              width: 57px;
              height: 3px;
              background: #666666;
              margin-left: 24px;
            }
            .time {
              width: 74px;
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #666666;
              line-height: 20px;
              margin-left: 24px;
              margin-top: 15px;
            }
          }
        }
      }
    }

    .right-info {
      width: 62%;
      height: 100%;

      .Mediabox:first-child {
        margin: 0px auto;
      }

      .Mediabox {
        width: 100%;
        height: auto;
        display: flex;
        margin: 10px auto;

        .content-left {
          width: 310px;
          height: 177px;

          img {
            width: 100%;
          }
        }

        .content-right {
          width: 300px;
          height: 140px;

          margin: 20px auto;
          cursor: pointer;

          .title-txt {
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }

          .small {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            line-height: 20px;
            margin: 10px auto;
          }
          .title-txt:hover {
            border-bottom: 1px solid rgb(255, 255, 255);
            transition: all 0.3s;
          }

          .line {
            width: 57px;
            height: 3px;
            background: #ffffff;
          }

          .time {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            line-height: 21px;
            margin: 10px auto;
          }
        }
      }
    }
  }

  .newMedia {
    width: 1000px;
    height: 200px;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    position: relative;

    .left-img {
      width: 355px;
      height: 176px;

      img {
        width: 355px;
        height: 100%;
      }
    }

    .center-txt {
      width: 426px;
      height: 176px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h3 {
        font-size: 22px;
        font-weight: 700;
        color: #ffffff;
      }

      a {
        color: #ffffff;
      }

      span {
        font-size: 17px;
        font-weight: 500;
        color: #ffffff;
      }
    }

    .right-img {
      width: 156px;
      height: 176px;
      display: flex;
      align-items: center;
    }
  }

  .newMedia:after {
    content: " ";
    position: absolute;
    bottom: 0;
    width: 1030px;
    height: 1px;
    background: url(../../../assets/img/line.png) no-repeat 50%;
    border-radius: 1px;
    left: 50%;
    transform: translate(-50%);
    filter: blur(0.5px);
  }
}
</style>