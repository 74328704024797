<template>
  <div
    class="kctxBg clearfix"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
    id="kctx"
  >
    <div class="title">
      <h3 class="titleTxt">课程体系</h3>
    </div>
    <div class="box">
      <div
        class="ytjy-left"
        ref="ytjyLeft"
        data-key="ytjyLeft"
        :class="{
          'animate__animated animate__lightSpeedInLeft': isVisible.ytjyLeft,
        }"
      >
        <p>影视表演</p>
        <p style="position: relative; top: 1.5%">音乐</p>
        <p style="position: relative; top: 6%">美术</p>
      </div>

      <div
        class="ytjy-right"
        ref="ytjyRight"
        data-key="ytjyRight"
        :class="{
          'animate__animated animate__slideInRight': isVisible.ytjyRight,
        }"
      >
        <p>播音主持</p>
        <p style="position: relative; top: 1.5%">舞蹈</p>
        <p style="position: relative; top: 6%">书法</p>
      </div>

      <div class="four-txt">
        <p
          style="position: relative; left: -3%"
          ref="fourTxt1"
          data-key="fourTxt1"
          :class="{
            'animate__animated animate__lightSpeedInLeft': isVisible.fourTxt1,
          }"
        >
          教学方式
        </p>
        <p
          style="position: relative; left: -0.5%"
          ref="fourTxt2"
          data-key="fourTxt2"
          :class="{
            'animate__animated animate__lightSpeedInLeft': isVisible.fourTxt2,
          }"
        >
          课程体系
        </p>
        <p
          style="position: relative; left: 2.1%"
          ref="fourTxt3"
          data-key="fourTxt3"
          :class="{
            'animate__animated animate__lightSpeedInRight': isVisible.fourTxt3,
          }"
        >
          教学理念
        </p>
        <p
          style="position: relative; left: 4.7%"
          ref="fourTxt4"
          data-key="fourTxt4"
          :class="{
            'animate__animated animate__lightSpeedInRight': isVisible.fourTxt4,
          }"
        >
          课程疏导
        </p>
      </div>

      <div class="footer-txt">
        <div
          class="content"
          ref="footerTxt1"
          data-key="footerTxt1"
          :class="{
            'animate__animated animate__lightSpeedInLeft': isVisible.footerTxt1,
          }"
          style="position: relative; left: -1%"
        >
          <p>线上+线下</p>
          <p>直播+录播</p>
        </div>
        <div
          class="content"
          ref="footerTxt2"
          data-key="footerTxt2"
          :class="{
            'animate__animated animate__lightSpeedInLeft': isVisible.footerTxt2,
          }"
          style="position: relative; left: 0.8%"
        >
          <p>6大专业课程</p>
          <p>满足艺术所需</p>
        </div>
        <div
          class="content"
          ref="footerTxt3"
          data-key="footerTxt3"
          :class="{
            'animate__animated animate__lightSpeedInRight':
              isVisible.footerTxt3,
          }"
          style="position: relative; left: 1%"
        >
          <p>激发艺术潜能</p>
          <p>成就艺术梦想</p>
        </div>
        <div
          class="content"
          ref="footerTxt4"
          data-key="footerTxt4"
          :class="{
            'animate__animated animate__lightSpeedInRight':
              isVisible.footerTxt4,
          }"
          style="position: relative; left: 2.2%"
        >
          <p>量身定制教学计划</p>
          <p>专业导师搭建演出平台</p>
        </div>
      </div>
    </div>

    <div class="bottom-btn" v-show="false">点击咨询</div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      backgroundImage: "", // 存储背景图片的URL
      isVisible: {
        ytjyLeft: false,
        ytjyRight: false,
        fourTxt1: false,
        fourTxt2: false,
        fourTxt3: false,
        fourTxt4: false,
        footerTxt1: false,
        footerTxt2: false,
        footerTxt3: false,
        footerTxt4: false,
      },
    };
  },
  methods: {
    setBackgroundImage() {
      const screenWidth =
        window.innerWidth || document.documentElement.clientWidth;
      if (screenWidth >= 2240) {
        this.backgroundImage = require("../../../assets/img/kctxBg1.png");
      } else {
        this.backgroundImage = require("../../../assets/img/kctxBg.png");
      }
    },
    handleScroll() {
      this.checkVisibility();
    },
    checkVisibility() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // 元素可见的阈值设置为 0.5
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const { target } = entry;
          const key = target.dataset.key;

          this.isVisible[key] = entry.isIntersecting;
        });
      }, options);

      Object.keys(this.isVisible).forEach((key) => {
        const element = this.$refs[key];
        observer.observe(element);
      });
    },
  },
  mounted() {
    if (window.location.hash === "#kctx") {
      const kctx = document.getElementById("kctx");
      kctx.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    this.setBackgroundImage(); // 页面加载后设置背景图片
    window.addEventListener("resize", this.setBackgroundImage); // 监听窗口大小变化
    window.addEventListener("scroll", this.handleScroll);
    this.checkVisibility();
    // 监听路由变化
    this.$router.afterEach((to, from) => {
      // 检查是否有锚点
      if (to.hash) {
        const anchor = to.hash.substring(1); // 去掉 '#' 符号
        const targetElement = document.getElementById(anchor);
        if (targetElement) {
          // 使用原生 JavaScript 滚动到锚点位置
          targetElement.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setBackgroundImage); // 组件销毁前移除事件监听
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
  

<style lang="less" scoped>
.kctxBg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 1214px;
  width: 100%;
  margin-top: -5px;
  position: relative;

  .box {
    width: 800px;
    height: 600px;

    margin: 0 auto;
    margin-top: 150px;
    position: relative;

    p {
      color: white;
    }

    .ytjy-left,
    .ytjy-right {
      width: 15%;
      height: 350px;

      position: absolute;
      left: 11%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
    }

    .ytjy-right {
      left: 76%;
    }

    .four-txt {
      width: 100%;
      height: 52px;
      position: absolute;
      top: 74.5%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .footer-txt {
      width: 100%;
      height: 50px;

      position: absolute;
      bottom: 2%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content {
        width: 160px;
        height: 45px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .title {
    margin-top: 90px;
  }

  .bottom-btn {
    position: absolute;
    left: 50%;
    width: 179px;
    height: 41px;
    background: #e20305;
    border-radius: 21px;
    transform: translate(-50%);
    cursor: pointer;
    bottom: 150px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 41px;
  }
}
</style>