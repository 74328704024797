var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"lazy-background",rawName:"v-lazy-background",value:(_vm.mxkcBg),expression:"mxkcBg"}],ref:"mxkc",staticClass:"mxkc clearfix",attrs:{"id":"mxkc"}},[_vm._m(0),_c('div',{directives:[{name:"lazy-background",rawName:"v-lazy-background",value:(_vm.testBg),expression:"testBg"}],staticClass:"test"},[_c('div',{staticClass:"text-info w"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,ref:`element-${item.id}`,refInFor:true,class:[
          item.className,
          'animate__animated',
          _vm.getAnimationClass(item.id),
          { 'animate__delay-1s': !item.isVisible },
        ],attrs:{"id":item.id}},[_c('ul',_vm._l((item.text),function(text,liIndex){return _c('li',{key:liIndex,on:{"click":function($event){return _vm.toPiano(text)}}},[_vm._v(" "+_vm._s(text)+" ")])}),0)])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',{staticClass:"titleTxt"},[_vm._v("明星课程")])])
}]

export { render, staticRenderFns }